import logo from "./logo.svg";
import "./App.css";
import moment from "moment";
import Select from "react-select";
// import { Button } from 'antd';
// import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import GameResultTable from "./JS/game_result_table";
import trackVisitor from "./utilities/tracker";
const momenttz = require("moment-timezone");

function App() {
  // const todayDate = moment(new Date()).tz('Asia').format('LLL')
  var todayDate = moment().tz("Asia/Kolkata").format("LLL");
  const currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");
 const [data, setData] = useState([]); // State to store data fetched from backend
 const [datagame, setDataFor] = useState([]); 
  useEffect(() => {
    trackVisitor();
  });
 useEffect(() => {
   fetch("https://api.sattakingvip.co.in/getData", {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       game_name: "",
       curr_date: currentDate,
       prev_date: prevDate,
       open_time: "market_sunday_time_open",
     }),
   })
     .then((response) => response.json())
     .then((json) => {
       // Sort data based on open_time
       const sortedData = json.sort((a, b) => {
         const timeA = moment(a.open_time, "HH:mm");
         const timeB = moment(b.open_time, "HH:mm");
         return timeA.diff(timeB);
       });

       // Set sorted data into state
       setData(sortedData);
     })
     .catch((error) => console.error(error));
 }, [currentDate, prevDate]);
 
  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);// Dependencies ensure useEffect runs when data or time changes

  return (
    <>
      <div className="App">
        <h6 className="appHead">SATTA KING UP</h6>

        <div className="banner">
          <artical className="text-center artical">
            <p>
              SATTAKING UP - SATTA KING DISAWAR, SATTA KING UP, GALI SATTA KING,
              SATTA KING 786, SATTA KING FAST, SATTA KING DELHI, SATTA KING
              CHART, TODAY SATTA RESULT, SATTA KING ONLINE, LIVE SATTA RESULT
            </p>
          </artical>

          <h4>{todayDate}</h4>

          {datagame?.map((todayData, index) => (
            <div key={index}>
              <h2 className="mb-2">{todayData?.gameName}</h2>
              <h3 className="mb-1 blinking-text">
                {" "}
                {todayData?.result || "WAIT"}
              </h3>
              <button className="mb-4 ">
                {todayData?.gameName} Records 2024
              </button>
            </div>
          ))}
        </div>
      </div>
      <GameResultTable dayGameData={data} />
    </>
  );
}

export default App;

import moment from "moment";
import { Col, Table } from "antd";
import React, { useState, useEffect } from "react";
const momenttz = require("moment-timezone");

{
  /* <Table dataSource={dataSource} columns={columns} />; */
}
function MonthlyTable({ gamedata, dropValue }) {
  const [data, setData] = useState([]);
  const [monthSndYear, setMonth] = useState([]);
  let [columns, setcolumn] = useState([]);
  const [gameResult, setGameResult] = useState([]);
  // var date = moment().tz('Asia/Kolkata');
  // const currentMonth = date.month();
  // var currentMonthDays = date.daysInMonth();
  // var getCurrentYear = date.year();

  const currentMonth = moment().tz("Asia/Kolkata").month();
  const currentYear = moment().tz("Asia/Kolkata").year();
  const currentMonthDays = moment().tz("Asia/Kolkata").daysInMonth();
  useEffect(() => {
    if (gamedata) {
      setData(gamedata);
    }
  });
  var selectMonthDrop;
  useEffect(() => {
    if (dropValue) {
      setGameResult(dropValue);
      // console.log('gameResult', gameResult)
      if (gameResult == "") {
        selectMonthDrop = moment().tz("Asia/Kolkata").format("MMMM");
        // console.log('if======>', selectMonthDrop)
      } else {
        selectMonthDrop = moment(
          `${dropValue?.year}-${dropValue?.month}-01`
        ).format("MMMM");
        // console.log('else======>', selectMonthDrop)
      }
      // console.log(gameResult);
      // console.log()
    } else {
    }
  });
  useEffect(() => {
    if (data.length > 0) {
      let array = Object.keys(data[0]);
      for (let i = 0; i < array.length; i++) {
        array[i] = {
          title: array[i] == "day" ? selectMonthDrop : array[i],
          dataIndex: array[i],
          key: array[i],
        };
      }
      setcolumn(array);
    }
  }, [data]);
  useEffect(() => {
    // monthYear = new Date()
    // monthYear = "" + moment(monthYear).format('MMM-yyyy')
    // setMonth(monthYear)
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        month: currentMonth + 1,
        year: currentYear,
        gameName: "",
        result: "",
        days: currentMonthDays,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        // console.log(json)
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="monthYrTbl pb-3" id="monthTable">
      {!(dropValue == null) && (
        <h3
          className="text-center bg-dark text-white p-2"
          style={{ display: "block" }}
        >
          {/* {dropValue?.selectedOption?.value} MONTHLY RECORD CHART {dropValue?.selecYear?.value} */}
          {dropValue?.gameName} MONTHLY RECORD CHART {selectMonthDrop} -{" "}
          {dropValue?.year || currentYear}
        </h3>
      )}
      <div className="table-responsive" id="scrollbar1">
        <Table dataSource={data} columns={columns} pagination={false} />
      </div>
    </div>
  );
}

export default MonthlyTable;
